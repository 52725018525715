import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-567c9452"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login-container" }
const _hoisted_2 = { class: "login-title" }
const _hoisted_3 = { class: "tagline" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "login-body" }
const _hoisted_6 = ["src"]
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FlatButton = _resolveComponent("FlatButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("login.passwordNewTagline")), 1),
      _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.$t("login.passwordNewTitle")), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("img", {
        class: "unlock-img",
        src: require('images/unlock-success.svg'),
        alt: "unlock success"
      }, null, 8, _hoisted_6),
      _createElementVNode("p", {
        innerHTML: _ctx.$t('login.passwordNewSuccessMessage'),
        class: "forgot-pass-text"
      }, null, 8, _hoisted_7),
      _createVNode(_component_FlatButton, {
        text: _ctx.$t('login.passwordNewSuccessButton'),
        class: "forgot-confirm-btn w-full mt-5",
        onClick: _ctx.backToLogin
      }, null, 8, ["text", "onClick"])
    ])
  ]))
}