
import { defineComponent } from "vue";
import FlatButton from "@/components/atomics/button/FlatButton.vue";

export default defineComponent({
  name: "PasswrordNewSuccess",
  components: {
    FlatButton,
  },
  methods: {
    backToLogin() {
      this.$router.push({ name: "Login" });
    },
  },
});
